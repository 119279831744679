<template>
    <div>
        <div v-if="data.nodeType !== 'Root'" class="rltn-point-status">
            <span class="mb-0">{{ `상위 노드로부터 상속된 연관 관제점 : ${inheritPoints.length}건` }}</span>
            <button type="button" class="btn btn-sm btn-secondary" @click="showInheritPointModal">관제점 보기</button>
        </div>
        <div>
            <TableList
                ref="table-list"
                :title="''"
                :columns="columns"
                :rows="rltnPoint"
                :keyField="'ptName'"
                :toggleFields="['enabled']"
                :transCodes="transCodes"
                :controls="controls"
                :useFilter="false"
                :mode="'edit'"
                @button-click="handleListButtonClick"
                @toggle-changed="handleItemToggle"
                @row-click="handleListItemClick"
            />
            <!-- @button-click="handleListButtonClick" @row-click="handleItemClick" @toggle-changed="handleItemToggle" -->
            <!-- @toggle-changed="handleItemToggle" -->
            <RelationPointModal ref="RelationPointModal" :data="data" @save="addRltnPoint" />
            <InheritPointModal ref="InheritPointModal" />
        </div>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import TableList from "@src/views/component/v2.1/ListDetailView/TableList.vue";
import RelationPointModal from "../modal/RelationPointModal.vue";
import InheritPointModal from "../modal/InheritPointModal.vue";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    components: {
        TableList,
        InheritPointModal,
        RelationPointModal,
    },
    props: ["data"],
    data() {
        return {
            rows: [],
            columns: [
                {
                    label: "표시명",
                    field: "dispText",
                    type: "text",
                    // compositionFields: ['ptName', 'ptAddr'],
                    thClass: "text-center",
                    tdClass: "text-center font-weight-bold text-dark",
                },
                {
                    label: "관제점명",
                    field: "ptName",
                    type: "text",
                    // compositionFields: ['ptName', 'ptAddr'],
                    thClass: "text-center",
                    tdClass: "text-center font-weight-bold text-dark",
                },
                {
                    label: "관제점 ID",
                    field: "ptIdx",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center font-weight-bold text-dark",
                },
                {
                    label: "관제점 주소",
                    field: "ptAddr",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center font-weight-bold text-dark",
                },
                // {
                //     label: "관제점 그룹",
                //     field: "ptGroup",
                //     type: "text",
                // },
                {
                    label: "연관 유형",
                    field: "ptReltnType",
                    type: "text",
                },
                {
                    label: "영향 범위",
                    field: "affectRange",
                    type: "text",
                },
                {
                    label: "집계 유형",
                    field: "ptTypeCode",
                    type: "text",
                },
                // {
                //     label: "연동 장치",
                //     field: "deviceIdx",
                //     type: "text",
                // },
                // {
                //     label: "연동 모듈",
                //     field: "moduleIdx",
                //     type: "text",
                // },
                // {
                //     label: "단위 구분",
                //     field: "unitLargeCode",
                //     type: "text",
                // },
                {
                    label: "단위",
                    field: "unitSmallCode",
                    type: "text",
                },
                {
                    label: "사용여부",
                    field: "enabled",
                    type: "text",
                },
            ],
            transCodes: [
                {
                    field: "ptGroup",
                    codes: this.$store.state.commonCodes.pointGroup,
                },
                {
                    field: "ptReltnType",
                    codes: this.$store.state.commonCodes.ptReltnType,
                },
                {
                    field: "affectRange",
                    codes: this.$store.state.commonCodes.affectRange,
                },
                {
                    field: "ptTypeCode",
                    codes: this.$store.state.commonCodes.ptTypeCode,
                },
                {
                    field: "unitLargeCode",
                    codes: this.$store.state.unitTypes,
                },
                {
                    field: "unitSmallCode",
                    codes: this.$store.state.units,
                },
                {
                    field: "enabled",
                    codes: this.$store.state.commonCodes.enabled,
                },
            ],
            controls: [
                { event: "delete", label: "-", class: "btn-danger" },
                { event: "status", label: "사용", class: "bg-white text-dark", options: { enabled: "Y" } },
                { event: "status", label: "미사용", class: "bg-white text-dark", options: { enabled: "N" } },
                { event: "regist", label: "+", class: "btn-primary" },
            ],
            rltnPointRowData: [],
            rltnPoint: [],
            inheritPoints: [],
            rowIdx: null,
            popupMessages,
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    beforDestroyed() {},
    methods: {
        setRltnData(data) {
            this.rltnPoint = [];
            this.inheritPoints = [];
            this.rltnPointRowData = [];
            if (data) {
                if (data.mappedPoints.length > 0) {
                    this.rltnPointRowData = [...data.mappedPoints];
                    data.mappedPoints.forEach((item) => {
                        this.rltnPoint.push({
                            ...item.pointInfo,
                            dispText: item.dispText,
                            ptReltnType: item.ptReltnType,
                            affectRange: item.affectRange,
                        });
                    });
                }
                if (data.inheritPoints.length > 0) {
                    data.inheritPoints.forEach(async (point) => {
                        try {
                            let pointInfo = await backEndApi.points.getPointInfoById(point.ptIdx);
                            let nodeInfo = await backEndApi.sysNode.getSysNode(point.sysNodeIdx);
                            if (pointInfo.status !== 200) {
                                await this.alertWarning(`${pointInfo.data.message}`);
                                return;
                            }
                            if (nodeInfo.status !== 200) {
                                await this.alertWarning(
                                    `${nodeInfo.data.message}`,
                                    "",
                                    `${typeof nodeInfo.data.detail == "string" ? nodeInfo.data.detail : ""}`
                                );
                                return;
                            }
                            this.inheritPoints.push({
                                ...point,
                                ptAddr: pointInfo.data[0].ptAddr,
                                ptName: pointInfo.data[0].ptName,
                                ptTypeCode: pointInfo.data[0].ptTypeCode,
                                unitSmallCode: pointInfo.data[0].unitSmallCode,
                                sysNodeName: nodeInfo.data.sysNodeName,
                            });
                        } catch (e) {
                            console.error(e);
                        }
                    });
                }
            }
        },
        async handleItemToggle(key, field, status) {
            console.log(`key: ${key}, field: ${field}, status: ${status}`);
            this.rltnPoint.find((item) => item.ptName == key)[field] = status;
            console.log("rltnPoint", this.rltnPoint);
        },
        async handleListButtonClick(event, options) {
            const that = this;

            switch (event) {
                case "regist":
                    await that.registNew();
                    break;

                case "delete":
                    await that.deleteChecked();
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        async handleListItemClick(row, index, toggle, check) {
            const that = this;
            if (toggle || check) return;
            let find = this.rltnPointRowData.find((item) => item.pointInfo.ptIdx == row.ptIdx);
            if (find) {
                that.rowIdx = index;
                await this.$refs.RelationPointModal.setData({ ...find });
                await this.$refs.RelationPointModal.showRelationPointModal();
            }
        },
        async registNew() {
            this.rowIdx = null;
            await this.$refs.RelationPointModal.setData();
            await this.$refs.RelationPointModal.showRelationPointModal();
        },
        async showInheritPointModal() {
            await this.$refs.InheritPointModal.setData(this.inheritPoints);
            await this.$refs.InheritPointModal.showInheritPointMadal();
        },
        async addRltnPoint() {
            let point = this.$refs.RelationPointModal.ptInfo;
            if (point) {
                if (this.rowIdx !== null || undefined) {
                    let transPoint = {
                        ...point.pointInfo,
                        dispText: point.dispText,
                        ptReltnType: point.ptReltnType,
                        affectRange: point.affectRange,
                    };
                    await this.$set(this.rltnPointRowData, this.rowIdx, point);
                    await this.$set(this.rltnPoint, this.rowIdx, transPoint);
                    await this.$refs.RelationPointModal.hideRelationPointModal();
                } else {
                    let duplicate = this.rltnPoint.find((item) => item.ptIdx == point.pointInfo.ptIdx);
                    if (duplicate) {
                        this.alertNoti(popupMessages.SYSTEM_EQUIP_DUPLICATE_POINT_MESSAGE);
                        return;
                    } else {
                        this.rltnPointRowData.push(point);
                        this.rltnPoint.push({
                            ...point.pointInfo,
                            dispText: point.dispText,
                            ptReltnType: point.ptReltnType,
                            affectRange: point.affectRange,
                        });
                        this.$refs.RelationPointModal.hideRelationPointModal();
                    }
                }
            }
        },
        async deleteChecked() {
            try {
                const checked = this.$refs["table-list"].getCheckedRows();

                if (!this.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
                    if (!confirm.value) return;
                    checked.forEach(async () => {
                        this.rltnPoint = this.rltnPoint.filter((item) => {
                            return !checked.find((checkedProp) => checkedProp.ptIdx === item.ptIdx);
                        });
                        this.rltnPointRowData = this.rltnPointRowData.filter((item) => {
                            return !checked.find((checkedProp) => checkedProp.ptIdx === item.pointInfo.ptIdx);
                        });
                    });
                } else {
                    this.alertNoti(popupMessages.COMMON_DELETE_NOTI_POPUP_MESSAGE);
                }
            } catch (e) {
                this.alertNoti(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE);
                console.error(e);
            }
        },
    },
};
</script>

<style scoped>
.rltn-point-status {
    padding: 1rem;
    width: 100%;
    border: 1px solid #eee;
    border-radius: 10px;
    margin-bottom: 1rem;
    display: flex;
    background-color: #eee;
    justify-content: space-between;
    align-items: center;
}
</style>
