<template>
    <div>
        <div>
            <TableList
                ref="table-list"
                :title="''"
                :columns="columns"
                :rows="systemData"
                :keyField="'sysNodeIdx'"
                :toggleFields="['enabled']"
                :transCodes="transCodes"
                :controls="controls"
                :useFilter="false"
                :mode="'edit'"
                @button-click="handleListButtonClick"
                @toggle-changed="handleItemToggle"
                @row-click="handleListItemClick"
            />
            <!--  -->
        </div>
        <SubSystemModal ref="SubSystemModal" :data="data" @save="addSubGroup" />
        <AddNodeModal ref="AddNodeModal" :node="data" :routeType="$route.params.type" @save="AddSysNode" />
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import TableList from "@src/views/component/v2.1/ListDetailView/TableList.vue";
import SubSystemModal from "../modal/SubSystemModal.vue";
import AddNodeModal from "../modal/AddNodeModal.vue";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    components: {
        TableList,
        SubSystemModal,
        AddNodeModal,
    },
    props: ["data"],
    data() {
        return {
            rows: [],
            columns: [
                {
                    label: "노드 ID",
                    field: "sysNodeIdx",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center font-weight-bold text-dark",
                },
                {
                    label: "노드명",
                    field: "sysNodeName",
                    type: "text",
                    thClass: "text-center",
                    tdClass: "text-center font-weight-bold text-dark",
                },
                {
                    label: "노드 유형",
                    field: "nodeType",
                    type: "text",
                },
                {
                    label: "계통 유형",
                    field: "sysType",
                    type: "text",
                },
                {
                    label: "설비 유형",
                    field: "equipType",
                    type: "text",
                },
                {
                    label: "구역",
                    field: "zoneIdx",
                    type: "text",
                },
                {
                    label: "사용여부",
                    field: "enabled",
                    type: "text",
                },
            ],
            transCodes: [
                {
                    field: "nodeType",
                    codes: this.$store.state.commonCodes.nodeType,
                },
                {
                    field: "sysType",
                    codes: this.$store.state.transSysType,
                },
                {
                    field: "equipType",
                    codes: this.$store.state.transEquipType,
                },
                {
                    field: "zoneIdx",
                    codes: this.$store.state.zoneInfo,
                },
                {
                    field: "enabled",
                    codes: this.$store.state.commonCodes.enabled,
                },
            ],
            controls: [
                { event: "delete", label: "-", class: "btn-danger" },
                { event: "status", label: "사용", class: "bg-white text-dark", options: { enabled: "Y" } },
                { event: "status", label: "미사용", class: "bg-white text-dark", options: { enabled: "N" } },
                { event: "regist", label: "+", class: "btn-primary" },
            ],
            systemData: null,
            rowIdx: null,
            sysType: null,
            popupMessages,
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    beforDestroyed() {},
    methods: {
        setSystemData(node) {
            this.systemData = null;

            let checkSystemNode = node.subNodes.some((item) => item.nodeType == "System");

            if (checkSystemNode) {
                this.systemData = node.subNodes;
            } else {
                this.systemData = [];
            }
        },
        async handleListButtonClick(event, options) {
            const that = this;

            switch (event) {
                case "regist":
                    // await that.registNew();
                    await that.openAddModal("System");
                    break;

                case "delete":
                    await that.deleteChecked();
                    break;

                case "status":
                    await that.changeStatusChecked(options);
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        async handleItemToggle(key, field, status) {
            // this.systemData.find((item) => item.sysNodeName == key)[field] = status;
            await this.changeStatus(key, field, status);
        },
        async handleListItemClick(row, index, toggle, check) {
            const that = this;
            if (toggle || check) return;
            that.rowIdx = index;
            const find = this.systemData.find((item) => item.sysNodeName == row.sysNodeName);
            await that.$refs.SubSystemModal.setData({ ...find });
            await this.$refs.SubSystemModal.showSubSystemModal();
        },
        async registNew() {
            this.rowIdx = null;
            await this.$refs.SubSystemModal.setData(null, this.data.sysType);
            await this.$refs.SubSystemModal.showSubSystemModal();
        },
        addSubGroup() {
            let system = this.$refs.SubSystemModal.subSystemData;

            if (system) {
                // this.$set(this.data, this.data.length, addGroup)
                if (this.rowIdx !== null || undefined) {
                    this.$set(this.systemData, this.rowIdx, system);
                } else {
                    let duplicate = this.systemData.find((item) => item.sysNodeName == system.sysNodeName);
                    if (duplicate) {
                        this.alertNoti(popupMessages.SYSTEM_EQUIP_DUPLICATE_NODE_MESSAGE);
                        return;
                    } else {
                        this.$set(this.systemData, this.systemData.length, system);
                    }
                }
            }
        },
        async deleteChecked() {
            const that = this;

            try {
                let confirm = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
                if (!confirm.value) return;

                const checked = this.$refs["table-list"].getCheckedRows();
                if (!that.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        await backEndApi.sysNode.deleteSysNode(item.sysNodeIdx);
                    });
                    await this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
                    await this.$emit("refresh");
                } else {
                    this.alertNoti(popupMessages.COMMON_DELETE_NOTI_POPUP_MESSAGE);
                }
            } catch (e) {
                this.alertNoti(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE);
                console.error(e);
            }
        },
        async changeStatus(key, field, status) {
            // console.log(`key: ${key}, field: ${field}, status: ${status}`);
            const data = {};
            data["sysNodeIdx"] = key;
            data[field] = status;

            try {
                let result = await backEndApi.sysNode.setEnabled(data);
                if (result.status == 200) {
                    await this.alertNoti(popupMessages.COMMON_STATUS_CHANGE_SUCCESS_POPUP_MESSAGE);
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },
        async changeStatusChecked(options) {
            const that = this;

            let data = options;

            try {
                const checked = this.$refs["table-list"].getCheckedRows();
                if (!that.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        if (!that.isEmpty(data)) {
                            data["sysNodeIdx"] = item.sysNodeIdx;

                            if (data.hasOwnProperty("enabled")) {
                                await backEndApi.sysNode.setEnabled(data);
                            } else {
                                console.warn("Unknown Status Name");
                            }
                        }
                    });
                    await this.alertNoti(popupMessages.COMMON_STATUS_CHANGE_SUCCESS_POPUP_MESSAGE);
                    await this.$emit("refresh");
                } else {
                    this.alertNoti(popupMessages.COMMON_STATUS_CHANGE_NOTI_POPUP_MESSAGE);
                }
            } catch (e) {
                console.error(e);
                this.alertNoti(popupMessages.COMMON_STATUS_CHANGE_ERROR_POPUP_MESSAGE);
            }
        },
        async openAddModal(nodeType) {
            // this.$nextTick(async () => {
            //     if (!this.currentNode) this.alertNoti("노드를 선택해주세요.")
            //     else {
            //         await this.$refs.AddNodeModal.initData();
            //         await this.$refs.AddNodeModal.showAddNodeModal();
            //     }
            // })
            await this.$refs.AddNodeModal.initData(nodeType);
            await this.$refs.AddNodeModal.showAddNodeModal();
        },
        async AddSysNode(nodeInfo, sysNodeIdx, sumAvail) {
            let newNode = {
                nodeType: nodeInfo.nodeType,
                sysNodeName: nodeInfo.sysNodeName,
                sysType: nodeInfo.sysType,
                equipType: nodeInfo.equipType,
                mainNodeIdx: sysNodeIdx,
            };
            if (sumAvail == "Y") {
                newNode = {
                    ...newNode,
                    sumEnabled: nodeInfo.sumEnabled,
                    sumUnitType: nodeInfo.sumUnitType,
                    sumUnit: nodeInfo.sumUnit,
                    sumCondType: nodeInfo.sumCondType,
                    sumCond: nodeInfo.sumCond,
                    sumMethod: nodeInfo.sumMethod,
                };
            }
            try {
                let result = await backEndApi.sysNode.addSysNode(newNode);
                if (result.status == 200) {
                    await this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
                    await this.$refs.AddNodeModal.hideAddNodeModal();
                    await this.$emit("refresh");
                    // await this.$refs['tree-detail'].clearData();
                } else {
                    // let detail = "";
                    // if (result.data.errcode) await this.alertWarning(result.data.message);
                    // else {
                    //     result.data.detail.forEach((item) => (detail += item));
                    //     await this.alertWarning(result.data.message, "", detail);
                    // }
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
};
</script>
