<template>
    <div>
        <div>
            <TableList
                ref="table-list"
                :title="''"
                :columns="columns"
                :rows="equipData"
                :keyField="'sysNodeIdx'"
                :toggleFields="['enabled']"
                :transCodes="transCodes"
                :controls="controls"
                :useFilter="false"
                :mode="'edit'"
                @button-click="handleListButtonClick"
                @toggle-changed="handleItemToggle"
                @row-click="handleListItemClick"
            />
            <!--  -->
            <SubEquipModal ref="SubEquipModal" :data="data" @save="addSubEquip" />
            <b-sidebar
                v-model="sideBar"
                ref="equipSideBar"
                id="equipAddSideBar"
                bg-variant="light"
                backdrop-variant="secondary"
                width="33vw"
                @hidden="closeSidebar"
                no-header
                shadow
                right
                backdrop
            >
                <div v-if="sideBar" style="padding: 2rem; height: 100%">
                    <EquipSearch ref="equipSearch" :nodeInfo="data" :routeType="$route.params.type" />
                </div>
                <template #footer>
                    <div class="d-flex align-items-center" style="padding: 1rem; flex: 1">
                        <button
                            type="button"
                            class="btn btn-sm btn-primary"
                            style="flex: 1; margin-right: 0.5rem"
                            @click="setNewEquip"
                        >
                            {{ "추가" }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm btn-secondary"
                            style="flex: 1; margin-left: 0.5rem"
                            @click="closeSidebar"
                        >
                            취소
                        </button>
                    </div>
                </template>
            </b-sidebar>
        </div>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import TableList from "@src/views/component/v2.1/ListDetailView/TableList.vue";
import SubEquipModal from "../modal/SubEquipModal.vue";
import EquipSearch from "@src/views/component/v2.1/PointSearch/EquipSearch.vue";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    components: {
        TableList,
        SubEquipModal,
        EquipSearch,
    },
    props: ["data"],
    data() {
        return {
            rows: [],
            columns: [
                {
                    label: "노드 ID",
                    field: "sysNodeIdx",
                    type: "text",
                    // compositionFields: ['sysNodeName', 'sysNodeIdx'],
                    thClass: "text-center",
                    tdClass: "text-center font-weight-bold text-dark",
                },
                {
                    label: "노드명",
                    field: "sysNodeName",
                    type: "text",
                    // compositionFields: ['sysNodeName', 'sysNodeIdx'],
                    thClass: "text-center",
                    tdClass: "text-center font-weight-bold text-dark",
                },
                {
                    label: "계통 유형",
                    field: "sysType",
                    type: "text",
                },
                {
                    label: "설비 유형",
                    field: "equipType",
                    type: "text",
                },
                {
                    label: "구역",
                    field: "zoneIdx",
                    type: "text",
                },
                {
                    label: "사용여부",
                    field: "enabled",
                    type: "text",
                },
            ],
            transCodes: [
                {
                    field: "nodeType",
                    codes: this.$store.state.commonCodes.nodeType,
                },
                {
                    field: "sysType",
                    codes: this.$store.state.transSysType,
                },
                {
                    field: "equipType",
                    codes: this.$store.state.transEquipType,
                },
                {
                    field: "zoneIdx",
                    codes: this.$store.state.zoneInfo,
                },
                {
                    field: "enabled",
                    codes: this.$store.state.commonCodes.enabled,
                },
            ],
            controls: [
                { event: "delete", label: "-", class: "btn-danger" },
                { event: "status", label: "사용", class: "bg-white text-dark", options: { enabled: "Y" } },
                { event: "status", label: "미사용", class: "bg-white text-dark", options: { enabled: "N" } },
                { event: "regist", label: "+", class: "btn-primary" },
            ],
            equipData: null,
            rowIdx: null,
            sideBar: false,
            popupMessages,
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    beforDestroyed() {},
    methods: {
        async setEquipData(node) {
            this.equipData = null;

            let checkEquipNode = node.subNodes.some((item) => item.nodeType == "Equip");

            if (checkEquipNode) {
                this.equipData = node.subNodes;
            } else {
                this.equipData = [];
            }
        },
        async handleListButtonClick(event, options) {
            const that = this;

            switch (event) {
                case "regist":
                    // await that.registNew();
                    await that.showSideBar();
                    break;

                case "delete":
                    await that.deleteChecked();
                    break;

                // case 'refresh':
                //     await that.getDataList(event);
                //     break;

                case "status":
                    await that.changeStatusChecked(options);
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        async handleItemToggle(key, field, status) {
            await this.changeStatus(key, field, status);
        },
        async changeStatus(key, field, status) {
            // console.log(`key: ${key}, field: ${field}, status: ${status}`);
            const data = {};
            data["sysNodeIdx"] = key;
            data[field] = status;

            try {
                let result = await backEndApi.sysNode.setEnabled(data);
                if (result.status == 200) {
                    await this.alertNoti(popupMessages.COMMON_STATUS_CHANGE_SUCCESS_POPUP_MESSAGE);
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },
        async changeStatusChecked(options) {
            const that = this;

            let data = options;

            try {
                const checked = this.$refs["table-list"].getCheckedRows();
                if (!that.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        if (!that.isEmpty(data)) {
                            data["sysNodeIdx"] = item.sysNodeIdx;

                            if (data.hasOwnProperty("enabled")) {
                                await backEndApi.sysNode.setEnabled(data);
                            } else {
                                console.warn("Unknown Status Name");
                            }
                        }
                    });
                    await this.alertNoti(popupMessages.COMMON_STATUS_CHANGE_SUCCESS_POPUP_MESSAGE);
                    await this.$emit("refresh");
                } else {
                    this.alertNoti(popupMessages.COMMON_STATUS_CHANGE_NOTI_POPUP_MESSAGE);
                }
            } catch (e) {
                console.error(e);
                this.alertNoti(popupMessages.COMMON_STATUS_CHANGE_ERROR_POPUP_MESSAGE);
            }
        },
        async handleListItemClick(row, index, toggle, check) {
            const that = this;
            if (toggle || check) return;
            that.rowIdx = index;
            const find = this.equipData.find((item) => item.sysNodeIdx == row.sysNodeIdx);
            await that.$refs.SubEquipModal.setData({ ...find });
            await this.$refs.SubEquipModal.showSubEquipModal();
        },

        async registNew() {
            this.rowIdx = null;
            await this.$refs.SubEquipModal.setData();
            await this.$refs.SubEquipModal.showSubEquipModal();
        },
        addSubEquip() {
            let equip = this.$refs.SubEquipModal.subEquipData;

            if (equip) {
                // this.$set(this.data, this.data.length, addGroup)
                if (this.rowIdx !== null || undefined) {
                    this.$set(this.equipData, this.rowIdx, equip);
                } else {
                    let duplicate = this.equipData.find((item) => item.sysNodeName == equip.sysNodeName);
                    if (duplicate) {
                        this.alertNoti(popupMessages.SYSTEM_EQUIP_DUPLICATE_NODE_MESSAGE);
                        return;
                    } else {
                        this.$set(this.equipData, this.equipData.length, equip);
                    }
                }
            }
        },
        async deleteChecked() {
            const that = this;

            try {
                let confirm = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
                if (!confirm.value) return;

                const checked = this.$refs["table-list"].getCheckedRows();
                if (!that.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        await backEndApi.sysNode.deleteSysNode(item.sysNodeIdx);
                    });
                    await this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
                    await this.$emit("refresh");
                } else {
                    this.alertNoti(popupMessages.COMMON_DELETE_NOTI_POPUP_MESSAGE);
                }
            } catch (e) {
                this.alertNoti(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE);
                console.error(e);
            }
        },
        async setNewEquip() {
            let equips = await this.$refs.equipSearch.selectedEquip();
            if (equips.length > 0) {
                await equips.forEach(async (equip) => {
                    let node = {
                        mainNodeIdx: this.data.sysNodeIdx,
                        sysNodeName: equip.equipName,
                        nodeType: "Equip",
                        sysType: this.data.sysType,
                        equipType: this.data.equipType,
                        equipIdx: equip.equipIdx ? equip.equipIdx : null,
                    };
                    try {
                        let result = await backEndApi.sysNode.addSysNode(node);
                        if (result.status !== 200) {
                            // let detail = "";
                            // if (result.data.errcode) await this.alertWarning(result.data.message);
                            // else {
                            //     result.data.detail.forEach((item) => (detail += item));
                            //     await this.alertWarning(result.data.message, "", detail);
                            // }
                            this.alertWarning(
                                `${result.data.message}`,
                                "",
                                `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                            );
                            return;
                        }
                    } catch (e) {
                        console.error(e);
                    }
                });
                await this.alertNoti(popupMessages.SYSTEM_EQUIP_NODE_ADD_MESSAGE);
                await this.closeSidebar();
                await this.$emit("refresh");
            } else {
                try {
                    let nodeDetail = await backEndApi.sysNode.getSysNode(this.data.sysNodeIdx);
                    if (nodeDetail.status == 200) {
                        console.log("nodeDetail status 200");
                        let node = {
                            mainNodeIdx: this.data.sysNodeIdx,
                            sysNodeName: `DEFAULT NODE NAME (${nodeDetail.data.subNodes.length + 1})`,
                            nodeType: "Equip",
                            sysType: this.data.sysType,
                            equipType: this.data.equipType,
                            equipIdx: null,
                        };
                        try {
                            let result = await backEndApi.sysNode.addSysNode(node);
                            if (result.status !== 200) {
                                // let detail = "";
                                // if (result.data.errcode) await this.alertWarning(result.data.message);
                                // else {
                                //     result.data.detail.forEach((item) => (detail += item));
                                //     await this.alertWarning(result.data.message, "", detail);
                                // }
                                this.alertWarning(
                                    `${result.data.message}`,
                                    "",
                                    `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                                );
                                return;
                            } else {
                                await this.alertNoti(popupMessages.SYSTEM_EQUIP_NODE_ADD_MESSAGE);
                                await this.$emit("refresh");
                                this.closeSidebar();
                            }
                        } catch (e) {
                            console.error(e);
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        },
        showSideBar() {
            this.sideBar = true;
        },
        closeSidebar() {
            this.$refs.equipSideBar.hide();
            this.sideBar = false;
        },
    },
};
</script>
