<template>
    <div v-if="addNode">
        <vvo v-slot="v">
            <b-modal ref="AddNodeModal" centered size="md" @hidden="hideAddNodeModal">
                <template #modal-header>
                    <h5 class="mb-0">
                        {{ `${addNode.nodeType == "Group" ? "그룹" : "계통"} 노드 추가` }}
                    </h5>
                </template>
                <template #modal-footer>
                    <div style="display: flex; flex: 1">
                        <!-- @click="addNewNode" -->
                        <button type="button" class="btn btn-primary add-modal-btn" @click="onValidate(v, addNewNode)">
                            추가
                        </button>
                        <button type="button" class="btn btn-secondary add-modal-btn" @click="hideAddNodeModal">
                            닫기
                        </button>
                    </div>
                </template>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="sysNodeName">노드명</label>
                        <!-- <input v-model="addNode.sysNodeName" type="text" class="form-control" id="sysNodeName"> -->
                        <valid-input
                            :vid="'노드명'"
                            :classList="'form-control'"
                            :inputType="'text'"
                            :inputValue.sync="addNode.sysNodeName"
                            :placeholder="$t('노드명')"
                            :rules="rules.COMMON_NAME_RULE"
                            :errorMessage="validMessage.COMMON_NAME_VALID_MESSAGE"
                        >
                        </valid-input>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="nodeType">노드유형</label>
                        <select v-model="addNode.nodeType" id="nodeType" class="form-control">
                            <option :value="null">선택</option>
                            <option v-for="type in commonCodes.nodeType" :key="type.value" :value="type.value">
                                {{ type.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="sysType">계통 유형</label>
                        <select v-model="addNode.sysType" id="sysType" class="form-control" disabled>
                            <option :value="null">선택</option>
                            <option v-for="type in transSysType" :key="type.value" :value="type.value">
                                {{ type.text }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="equipType">{{ routeType == "energy" ? "부하 유형" : "설비 유형" }}</label>
                        <select v-model="addNode.equipType" id="equipType" class="form-control">
                            <option :value="''">선택</option>
                            <option
                                v-for="type in systemTypeList.allowedEquipTypes"
                                :key="type.equipType"
                                :value="type.equipType"
                            >
                                {{ type.equipTypeName }}
                            </option>
                        </select>
                        <!-- <valid-select :vid="`설비 유형`" :vidName="'설비 유형'" :inputValue.sync="addNode.equipType"
                            :selectOption="systemTypeList.allowedEquipTypes" :rules="rules.COMMON_SELECT_REQUIRED"
                            :setValueCode="'equipType'" :setValueCodeName="'equipTypeName'"
                            :errorMessage="validMessage.EQUIP_TYPE_CATEGORY_VALID_MESSAGE">
                        </valid-select> -->
                    </div>
                </div>
                <div v-if="systemTypeList.sumAvail == 'Y'" class="form-row">
                    <div class="form-group col-md-4">
                        <label for="sumEnabled">계통 자동집계 사용여부</label>
                        <select v-model="addNode.sumEnabled" id="sumEnabled" class="form-control">
                            <option :value="null">선택</option>
                            <option v-for="value in commonCodes.enabled" :key="value.value" :value="value.value">
                                {{ value.text }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="sumUnitType">집계값 유형</label>
                        <select v-model="addNode.sumUnitType" id="sumUnitType" class="form-control">
                            <option :value="null">선택</option>
                            <option
                                v-for="unitType in $store.state.unitTypes"
                                :key="unitType.value"
                                :value="unitType.value"
                            >
                                {{ unitType.text }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="sumUnit">집계값 단위</label>
                        <select v-model="addNode.sumUnit" id="sumUnit" class="form-control">
                            <option :value="null">선택</option>
                            <option
                                v-for="unit in $store.state.unitCodes[addNode.sumUnitType]"
                                :key="unit.value"
                                :value="unit.value"
                            >
                                {{ unit.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <div v-if="systemTypeList.sumAvail == 'Y'" class="form-row">
                    <div class="form-group col-md-6">
                        <label for="sumCondType">집계 조건 유형</label>
                        <select v-model="addNode.sumCondType" id="sumCondType" class="form-control">
                            <option :value="null">선택</option>
                            <option v-for="type in commonCodes.sumCondType" :key="type.value" :value="type.value">
                                {{ type.text }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="sumCond">집계 조건문</label>
                        <input
                            v-model="addNode.sumCond"
                            id="sumCond"
                            class="form-control"
                            :disabled="addNode.sumCondType !== 'Input'"
                        />
                    </div>
                </div>
            </b-modal>
        </vvo>
    </div>
</template>

<script>
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import { mapState } from "vuex";

export default {
    props: ["node", "routeType"],
    components: {},
    data() {
        return {
            addNode: null,
            rules,
            validMessage,
        };
    },
    created() {},
    mounted() {},
    watch: {},
    computed: {
        ...mapState({
            commonCodes: (state) => state.commonCodes,
        }),
        ...mapState({
            transSysType: (state) => state.transSysType,
        }),
        ...mapState({
            transEquipType: (state) => state.transEquipType,
        }),
        systemTypeList() {
            let find = this.$store.state.systemTypeList.find((type) => type.sysType == this.node.sysType);
            find.allowedEquipTypes.forEach((item) => {
                let findEquipType = this.$store.state.equipTypeList.find((type) => type.equipType == item.equipType);
                item.equipTypeName = findEquipType.equipTypeName;
            });
            return find;
        },
    },
    methods: {
        initData(nodeType) {
            this.addNode = {
                sysNodeName: null,
                nodeType: nodeType,
                sysType: this.node.sysType,
                equipType: this.node.equipType ? this.node.equipType : "",
            };

            if (this.systemTypeList.sumAvail == "Y") {
                this.addNode = {
                    ...this.addNode,
                    sumEnabled: "N",
                    sumUnitType: "elec",
                    sumUnit: "kWh",
                    sumCondType: null,
                    sumCond: null,
                    sumMethod: "SubNode",
                };
            }
        },
        showAddNodeModal() {
            this.$refs.AddNodeModal.show();
        },
        async hideAddNodeModal() {
            await this.$refs.AddNodeModal.hide();
            this.addNode = null;
        },
        addNewNode() {
            this.$emit("save", this.addNode, this.node.sysNodeIdx, this.systemTypeList.sumAvail);
        },
    },
};
</script>

<style scoped>
.add-modal-btn {
    flex: 1;
    margin: 0px 5px 0px 5px;
}
</style>
